.container{
    width: 100%;
    position: absolute;
    bottom: 0;
    
    display: flex;
    align-items: center;

    padding: 0 50px 65px 50px;
    user-select: none;
    font-size: 12px;
    min-width: 360px;
    pointer-events: none;
}

@media screen and (max-width: 1360px) {

    .container{
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .container{
        padding: 0 50px 44px 50px;
    }

}



/* Button  */

.buttonTitle{
    /*font-size: 12px;*/
    text-transform: uppercase;
    position: absolute;
    top: -30px;
}

.buttons {
    display: flex;
}

.buttons > li:nth-child(1) {
    margin-right: 21px;
}

.buttons > li:nth-child(2) {
    margin-right: 21px;
}

@media screen and (max-width: 800px) {

    /*
    .buttons > li:nth-child(1) {
        margin-right: 0px;
    }

    .buttons > li:nth-child(2) {
        margin-right: 0px;
        position: absolute;
        left: calc(((100% - (20px*6)) * 0.1666 + 40px));
    }
    
    .buttons > li:nth-child(3) {
        margin-right: 0px;
        position: absolute;
        left: calc(((100% - (20px*6)) * 0.1666 * 2 + 60px));
    }
    */
}




/* Slider */

.slidersContainer {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    text-transform: uppercase;
    /*font-size: 12px;*/
}

.sliderContainer {
    width: 226px;
    pointer-events: all;
}

.sliderTitle{
    position: relative;
    top: -10px;
}


.sliderWrapper {
    display: flex;
    align-items: flex-end;
}

.sliderWrapper > div {
    position: relative;
    /*width: 100%;*/
    display: flex;
}

.sliderWrapper label {
    width: 68px;
    margin-right: 15px;
}

.sliderOn {
    /*display: flex;*/
    visibility: visible;
    position: inherit;
}

.sliderOff {
    /*display: none;*/
    visibility: hidden;
    position: absolute;

}


@media screen and (max-width: 1360px) {

    .slidersContainer {
        order: 1;
        margin-top: 29px;
        width: 100%;
        justify-content: space-between;
        /*font-size: 10px;*/
    }

    .sliderContainer{
        /*width: calc(90%/3) !important;*/
        width: calc(90%/3);
    }
    
    .sliderWrapper {
        /*width: 100%;*/
    }
}  


@media screen and (max-width: 800px), (max-height: 380px) {

    .container{
        padding: 0 20px 27px 20px;
        font-size: 10px;
    }

    .buttonTitle{
        /*font-size: 10px;*/
        top: -25px;
    }

    .slidersContainer {
        display: table;
    }
    .sliderContainer{
        width: 100% !important;
    }

    .sliderWrapper label {
        /*width: calc(((100% - (20px*6)) * 0.1666 + 40px));*/
        width: 80px;
        margin-right: 0;
    }

    /*
    .slidersContainer > div:nth-child(2){
        padding-top: 13px;
    }
    
    .slidersContainer > div:nth-child(3){
        padding-top: 13px;
    }
    */

    .sliderOffset {
        padding-top: 13px;
    }

}



@media screen and (max-width: 1360px) and (max-height: 520px) {

    .container{
        flex-wrap: unset;
        margin-top: 0;
    }

    .sliderTitle{
        position: absolute;
        top: -30px;
    }

    .slidersContainer {
        order: 0;
        margin-top: 0px;
        width: 100%;
        justify-content: space-between;
        /*font-size: 10px;*/
    }

    .slidersContainer {
        display: table;
    }


    .sliderContainer {
        width: 100%;
        padding: 1px 30px
    }

}

@media screen and (max-height: 380px) {
    .sliderTitle{
        position: absolute;
        top: -25px;
    }

    .buttons > li:nth-child(1) {
        margin-right: 11px;
    }
    
    .buttons > li:nth-child(2) {
        margin-right: 11px;
    }

    .sliderContainer {
        padding: 0 10px
    }

}

@media screen and  (max-width: 800px) and (max-height: 520px) {

    .sliderTitle{
        position: absolute;
        top: -25px;
    }

    .buttons > li:nth-child(1) {
        margin-right: 11px;
    }
    
    .buttons > li:nth-child(2) {
        margin-right: 11px;
    }

    .sliderContainer {
        padding: 0 10px
    }

}