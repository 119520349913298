.button {
    cursor: pointer;
    text-transform: uppercase;
    /*border:solid;*/
    /*border-width: 1px;*/
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*border-color: #fff;*/
    position: relative;
    overflow: hidden;
    z-index: 1;
    pointer-events: all;
}

.border {
    position: absolute;
    width: 70px;
    height: 70px;

    border:solid;
    border-width: 1.4px;
    border-color: #fff;
    border-radius: 50%;
}

@media (resolution < 1.5dppx) {
    .border {
        border-width: 2px;
    }
}

.text{
    color: #fff;
    z-index: 1;
    font-size: 12px;
}

.buttonType {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.buttonDefaultAngle {
    width: 189px;
    height: 70px;

    cursor: pointer;
    text-transform: uppercase;
    /*border:solid;*/
    /*border-width: 1px;*/
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*border-color: #fff;*/
    position: relative;
    overflow: hidden;

    border-radius: 78px;

    /*
    border-radius: 78px;
    border:solid;
    border-width: 1px;
    border-color: #fff;
    */
}

.borderDefaultAngle {
    position: absolute;
    width: 189px;
    height: 70px;

    border:solid;
    border-width: 1.4px;
    border-color: #fff;
    border-radius: 78px;
}

@media (resolution < 1.5dppx) {
    .borderDefaultAngle {
        border-width: 2px;
    }
}

.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
}

.bgDefaultAngle {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 78px;
}


@media screen and (max-width: 800px), (max-height: 380px) {

    .button {
        font-size: 10px;
    }

    .text {
        font-size: 10px;
    }

    .border {
        width: 48px;
        height: 48px;
    }
    

    .buttonType {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    
    .buttonDefaultAngle {
        width: 118px;
        height: 48px;
        border-radius: 78px;
    }

    .borderDefaultAngle {
        width: 118px;
        height: 48px;
    }
    
}