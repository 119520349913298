.container {
    padding: 60px 50px 0 50px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-end;
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.path {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
}

.path svg {
    width: 100%;
    height: 100%;
    display: block;
}

.container h1 {
    font-size: 100px;
    text-transform: uppercase;
    line-height: 1.3em;
}

.container h2 {
    font-weight: 500;
}

.col2 > h2:nth-child(1) {
    padding-bottom: 1rem;
}

.col2 > h2:nth-child(3) {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.col3 > h2:nth-child(1) {
    padding-bottom: 1rem;
}

.col3 > ul > li {
    padding-bottom: 1rem;
}

.innerContainer{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 58px;
    font-size: 18px;
    /*padding-bottom: 90px;*/
    padding-top: 85px;
    padding-bottom: 45px;
}

.innerContainer > p {
    position: relative;
    /*margin-top: 3rem;*/
}

.col1 {
    grid-column: 1/6;
    display: flex;
    align-items: flex-end;
}

.col2 {
    grid-column: 7/10;
}

.col3 {
    grid-column: 10/13;
}

.close {
    user-select: none;
    position: absolute;
    top: 58px;
    right: 50px;
    font-size: 12px;
    cursor: pointer;
    pointer-events: all;
}

.button {
    font-size: 12px;
    user-select: none;
    position: fixed;
    pointer-events: none;
    width: 100%;
    height: 100%;
    padding-right: 50px;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
}

.button > div {
    pointer-events: all;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: solid;
    border-width: 1px;
    border-color: #fff;
    cursor: pointer;
}

.anchor {
    display: block;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
    cursor: pointer;
    pointer-events: all;
}

.social {
    display: flex;
}

.social > li > a {
    display: inline-block;
}

.social > li:nth-child(1)::after {
    content: "/";
    padding: 0 5px;
}

.title {
    display: none;
}

.columnsContainer {
    display: contents;
}

.columnsWrapper {
    display: contents;
}


@media screen and (max-width: 1360px) {


    .container h1 {
        /*padding-top: 58px;*/
        display: none;
    }

    .title {
        display: initial;
        position: absolute;
        top: 58px;
        left: 50px;
        text-transform: uppercase;
        font-size: 12px;
    }

    .container {
        padding: 60px 50px 60px 50px;
        /*height: auto;*/
        /*display:inherit;*/
        /*padding: 303px 50px 0 50px;*/
    }

    .innerContainer {
        grid-template-rows: repeat(3, 1fr);

        padding-top: 0;
        padding-bottom: 0;
    }

    .innerContainer > p {
        display: flex;
        align-items: center;
        margin-top: 0rem;
        padding-top: 0;
        padding-bottom: 0;
        grid-row: 2/3;
    }
    

    .col1 {
        grid-column: 1/12;
    }

    .col2 {
        font-size: 18px;
        grid-column: 1/5;
        font-size: 18px;
        grid-row: 3/3;

    }
    
    .col3 {
        font-size: 18px;
        grid-column: 7/12;
        font-size: 18px;
        grid-row: 3/3;

    }
    
    .columnsContainer {
        display: flex;
        /*grid-row: 3/3;*/
        position: absolute;
        bottom: 30px;
    }
    
    .columnsWrapper {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }
    

}

@media screen and (max-width: 1360px) , (max-height: 768px) {
    .container {
        align-content: initial;
        align-items: initial;    
    }
}


@media screen and (max-width: 800px)  {

    .container {
        padding: 226px 20px 0 20px;
        align-content: initial;
        align-items: initial;
    
    }

    .title {
        font-size: 10px;
        top: 40px;
        left: 20px;
    }

    .container h1 {
        font-size: 50px;
        display: none;

    }
    
    .innerContainer{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: unset;

        column-gap: 20px;

    }

    .innerContainer > p {
        display: initial;
        grid-row: unset;
    }
    
    .col1 {
        font-size: 16px;
        grid-column: 1/5;
    }

    .col2 {
        font-size: 16px;
        grid-column: 1/5;
        padding-bottom: 108px;
        grid-row: unset;
        padding-top: 170px;
    }
    
    .col3 {
        font-size: 16px;
        grid-column: 1/5;
        grid-row: unset;
    }

    .close {
        font-size: 10px;
        top: 40px;
        right: 20px;
    }

    .button {
        font-size: 10px;
        padding-right: 20px;
    }
    .columnsContainer {
        display: contents;
    }
    
    .columnsWrapper {
        display: contents;
    }

}

@media screen and (max-width: 1360px) and (max-height: 800px) {

    .container {
        padding: 226px 20px 0 20px;
        align-content: initial;
        align-items: initial;
    
    }

    .title {
        font-size: 10px;
        top: 40px;
        left: 20px;
    }

    .container h1 {
        font-size: 50px;
        display: none;

    }
    
    .innerContainer{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: unset;

        column-gap: 20px;

    }

    .innerContainer > p {
        display: initial;
        grid-row: unset;
    }
    
    .col1 {
        font-size: 16px;
        grid-column: 1/5;
    }

    .col2 {
        font-size: 16px;
        grid-column: 1/5;
        padding-bottom: 108px;
        grid-row: unset;
        padding-top: 170px;
    }
    
    .col3 {
        font-size: 16px;
        grid-column: 1/5;
        grid-row: unset;
    }

    .close {
        font-size: 10px;
        top: 40px;
        right: 20px;
    }

    .button {
        font-size: 10px;
        padding-right: 20px;
    }
    .columnsContainer {
        display: contents;
    }
    
    .columnsWrapper {
        display: contents;
    }


}


@media screen and  (max-width: 800px) and (max-height: 520px) {
    .container {
        min-width: 425px;
    }
}