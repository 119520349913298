.container {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 360px;
}

.wrapper {
    padding-right: 50px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.button{
    pointer-events: all;
    width: 43px;
    height: 43px;
    
    border-radius: 50%;
    /*
    border-radius: 50%;
    border: solid;
    border-width: 1px;
    border-color: #fff;
    */
    
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.border {
    position: absolute;
    width: 43px;
    height: 43px;

    border:solid;
    border-width: 1.4px;
    border-color: #fff;
    border-radius: 50%;
}

@media (resolution < 1.5dppx) {
    .border {
        border-width: 2px;
    }
}

.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50%;

}

.sun{
    width: 21px;
    height: 21px;
    position: absolute
}

.moon{
    width: 18px;
    height: 18px;
    position: absolute
}


@media screen and (max-width: 800px), (max-height: 380px) {

    .wrapper {
        padding-right: 20px;
        height: 72px;
    }
    
    .button{
        width: 31px;
        height: 31px;
    }

    .border {
        width: 31px;
        height: 31px;
    }

    .sun{
        width: 15px;
        height: 15px;
    }

    .moon{
        width: 13px;
        height: 13px;
    }
    
}

@media screen and  (max-width: 800px) and (max-height: 520px) {
    .container {
        min-width: 425px;
    }
}