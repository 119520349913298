.container {
    position: absolute;
    top: 0;

    pointer-events: none;
    width: 100%;
    height: 100%;

    display: flex;
    align-content: center;
    align-items: center;

    padding: 0px 50px 0 50px;
}

.wrapper {
    display: flex;
    text-transform: uppercase;
    font-size: 12px;
    position: relative;
    
}

.wrapper > div {
    pointer-events: all;
}

.wrapper svg {
    position: relative;
    width: 27px;
    bottom: 2px;
    
}

.wrapper > p {
    position: absolute;
    top: 37px;
    overflow: hidden;
}

.text span{
    display: block;
}

@media screen and (max-width: 1360px) {   
}

@media screen and (max-width: 800px), (max-height: 380px) {

    .container {
        padding: 0px 20px 0 20px;
    }

    .wrapper {
        font-size: 10px;
    }

    .wrapper svg {
        width: 20px;
        bottom: 0px;
        margin-right: 6px;
    }
}

