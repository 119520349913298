.container{
    position: absolute;
    top: 0;
    padding: 58px 50px 0 50px;
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-width: 360px;
    pointer-events: none;
}

.container h1{
    cursor: pointer;
    pointer-events: all;    
}

.about {
    cursor: pointer;
    pointer-events: all;
}

@media screen and (max-width: 800px), (max-height: 380px) {

    .container{
        font-size: 10px;
        padding: 40px 20px 0 20px;
    }
        
}

@media screen and  (max-width: 800px) and (max-height: 520px) {
    .container {
        min-width: 425px;
    }
}