.container {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    min-width: 360px;
    
}

.button {
    margin-right: 116px;
    margin-top: 51px;

    /*width: 43px;*/
    /*height: 43px;*/

    width: 30px;
    height: 30px;

    border-radius: 50%;
    /*border-width: 1px;*/
    /*border-style: solid;*/
    /*border-color: #FFF;*/

    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    cursor: pointer;
}

.wrapper {
    width: 17px;
    display: flex;
    justify-content:space-between;
    align-items: flex-end;
    height: 12px;
}

.wrapper > div {
    width: 1px;
    height: 12px;
    background-color: #fff;
}

@media screen and (max-width: 800px), (max-height: 380px) {

    /*
    .container{
        padding: 0px 20px 0 20px;
    }

    .wrapper {
        width: 12px;
    }    

    .button {
        width: 34px;
        height: 34px;
    }

    .wrapper > div {
        height: 10px;
    }
    */


    .button {
        margin-top: 31px;
        margin-right: 68px;
    }
}

@media screen and  (max-width: 800px) and (max-height: 520px) {
    .container {
        min-width: 425px;
    }
}