.container {
    top: 0;
    left: 0;
    position: absolute;
    padding: 0 50px 150px 50px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,1);

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 30px;

    z-index: 30000000;
}

.container > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: relative;

    grid-column: 1/13;
}

.title {
    text-transform: uppercase;
    font-size: 128px;
    line-height: 1.2em;

}

.trailsText {
    position: relative;
    width: 100%;
    height: 1.2em;
    line-height: 1.2em;
    will-change: transform, opacity;
    overflow: hidden;
    opacity: 0;
  }

  .trailsText > div {
    padding-right: 0.05em;
    overflow: hidden;
  }
  
.line {
    height: 2px;
    width: 100%;
    background-color: #fff;
}


.container > div > h1 > br:nth-child(1){
    display: none;
}

.text {
    position: absolute;
    bottom: 50px;
    font-size: 16px;
    grid-column: 9/13;
    text-indent: calc(((100% - (20px*6)) * 0.083333333333333 * 2 + 10px));
    padding-left: calc(((100% - (20px*6)) * 0.083333333333333 * 1 + 10px));
    overflow: hidden;
    will-change: transform, opacity;

}

.text > span{
    display: block;
}

.wrapper {
    display: none;
}

 .start {
    font-size: 50px;
    text-transform: uppercase;
    text-align: right;
    height: 1.2em;
    will-change: transform, opacity;
    overflow: hidden;
}



@media screen and (max-width: 1360px), (max-height: 720px) {
    .container {
        padding: 0 50px 50px 50px;
    }   

    .container h1 {
        font-size: 100px;
    }

    .container  h1 > br:nth-child(1){
        display: initial;
    }

    .container h1 > span::after {
        content: "\A";
    }

    .text {
        display: none;
    }

    .wrapper {
        display: initial;
    }

    .line{
        margin: 37px 0px;
    }

}

@media screen and (max-width: 800px), (max-height: 480px) {
    .container {
        padding: 0 20px 35px 20px;
    }  
    
    .container h1 {
        font-size: 50px;
    }
    .wrapper button {
        font-size: 25px;
        text-transform: uppercase;
    }

    .start {
        font-size: 25px;
    }

    .line{
        margin: 30px 0px;
    }

}

