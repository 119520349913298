.mouseFollower{
    width: 45px;
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
    /*background-color: #FFF;*/
    border: solid;
    border-width: 1px;
    z-index: 300000000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    opacity: 0;
}

.dot {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
}

.label {
    opacity: 0;
    position: absolute;
    color: #fff;

}

.arrow {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 65px;
    opacity: 0;
}

.arrow svg {
    width: 11px;
}

.arrow >svg:nth-child(2) {
    transform: rotate(180deg);
}



@media (hover: none) {

    .mouseFollower{
        visibility: hidden;
    }


}