.container {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    min-width: 360px;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;
}


.button {
    bottom: 170px;
    position: relative;
    width: 189px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 78px;
    pointer-events: all;
    cursor: pointer;
    pointer-events: none;;
}

.border {
    position: absolute;
    width: 189px;
    height: 70px;

    border:solid;
    border-width: 1.4px;
    border-color: #fff;
    border-radius: 78px;
    
}

@media (resolution < 1.5dppx) {
    .border {
        border-width: 2px;
    }
}


.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    /*background: #fff;*/
    border-radius: 78px;
}

.text {
    color: #fff;
    z-index: 1;
    font-size: 12px;
    text-transform: uppercase;   
}

.strobe {
    width: 100vw;
    height: 100vh;
    background: #fff;    
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000000000;
    display: none;
    pointer-events: none;
}

.path {
    width: 22px;
    height: 22px;
    display: none;
}

@media screen and (max-width: 1360px) {

    .container {
        justify-content: flex-end;
    }

    .button {
        bottom: 119px;
        right: calc((100vw - 252px) / 2);
    }    
}


@media screen and (max-width: 800px),  (max-height: 520px) {
 
    .container {
        align-items: flex-start;
        justify-content: flex-end;
    }

    .button {
        bottom: 0;
        width: 22px;
        height: 22px;
        right: 25px;
        top: calc(25% - 2px);
        border-radius: 0;
        opacity: 1 !important;
    }
    
    .bg {
    }

    .border {
        display: none;
    }
    
    .text {
        display: none;
    }


    .path {
        width: 22px;
        height: 22px;
        display: initial;
        position: relative;
        top:140%
    }
}


@media screen and (min-width: 801px) and (max-height: 520px) {
    .button {
        right: 60px;
    }
    
}

@media screen and  (max-height: 380px) {
    .button {
        right: 25px;
    }
    
}

