.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
}

.ui {
  opacity: 0;
}

.hooksMain > div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  background: lightcoral;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  opacity: 0.6;
}

.hooksMain > div:nth-child(1) {
  width: 60px;
  height: 60px;
}

.hooksMain {
  top: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  filter: url('#goo');
  overflow: hidden;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}