.container {
    opacity: 0;
    display: flex;
}


.c1{
    animation: anim 3s 0.1s infinite linear normal;
}

.c2{
    animation: anim 3s 1.1s infinite linear normal;
}

.c3{
    animation: anim 3s 2.1s infinite linear normal;
}

.wrapper {
    width: 80px;
    height: 80px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;

}

.circle {
    width: 80px;
    height: 80px;
    top: 0;
    left: 0;
    position: absolute;
    border:2px  solid #fff;
    border-radius: 50%;
    transform: scale(0,0);
}

@keyframes anim {
    0% {
        transform: scale(0, 0);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
                        

}